import React from 'react'
import { Link } from "react-router-dom";
import { FaYoutube, FaLinkedin, FaTwitter } from "react-icons/fa";
import { PRIVACY_POLICY_CANDIDATES } from '../../constants';

const Footer = ({ color }) => {
    
    return (
        <>
            <div className="w-full flex-shrink-0">
                {/* container design start */}
                <div className="h-16 md:h-24 bg-no-repeat bg-repeat-x bg-contain" style={{ backgroundImage: "url(/images/pattern.svg)" }}>
                    <div className={`w-1/4 h-full ${color}`}></div>
                </div>
                {/* container design end */}

                {/* container content start */}
                <div className="relative bg-blue-primary px-8 lg:px-20 py-10">

                    {/* news grid start */}
                    <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-8 gap-y-4 lg:gap-y-0">
                        {/* grid item start */}
                        <div className="border-l-4 border-red-primary px-4 grid-col-span-1">
                            <Link to="/contact-us" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                Contact us
                            </Link>
                            <Link to="/customer-service" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                Customer Service
                            </Link>
                            <Link to="/research" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                Investigator Initiated Research
                            </Link>
                            <Link to="/donations-and-grants" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                Donations and Grants
                            </Link>
                            <Link to="/product-safety" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                Product Safety and Patient Card
                            </Link>
                        </div>
                        {/* grid item end */}

                        {/* grid item start */}
                        <div className="border-l-4 border-red-primary px-4 grid-col-span-1">
                            <Link to="/terms" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                Terms of use
                            </Link>
                            <Link to="/privacy" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                Privacy Policy
                            </Link>
                            <Link to="/cookies" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                Cookie Policy
                            </Link>
                            <a href={PRIVACY_POLICY_CANDIDATES} target='_blank' className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter  lg:mb-3">
                                Privacy Policy Candidates
                            </a>
                            <Link to="/legal" className="text-xs md:text-sm lg:text-base mb-1 block text-gray-lighter lg:mb-3">
                                Legal Notes
                            </Link>
                        </div>
                        {/* grid item end */}

                        {/************** grid item 3 start ************/}
                        <div className="border-l-4 border-red-primary px-4 ">
                            <span className="block text-gray-lighter text-sm xl:text-base mb-3">
                                FOLLOW US ON
                            </span>
                            <div className='flex justify-start items-center'>
                                <a href="https://twitter.com/CORCYM_OFFICIAL" target="_blank" rel="noreferrer"
                                    className="social-link pr-2" style={{ float: "left" }}>
                                    {/* <FaTwitter style={{width: "1.5em", height: "1.5em", fill: "white"}}/> */}
                                    <img src="/images/footer/twitter.png" alt="twitter_logo" className='w-8 h-8 cursor-pointer transform hover:scale-110' />
                                </a>
                                <a href="https://www.linkedin.com/company/corcym" target="_blank" rel="noreferrer"
                                    className="social-link px-2" style={{ float: "left" }}>
                                    {/* <FaLinkedin style={{width: "1.5em", height: "1.5em", fill: "white"}}/> */}
                                    <img src="/images/footer/linkdIn.png" alt="linkdIn_logo" className='w-8 h-8 cursor-pointer transform hover:scale-110' />
                                </a>
                                <a href="https://www.youtube.com/channel/UCem2dzdF2kzV-gL0plAHVEA" target="_blank" rel="noreferrer"
                                    className="social-link px-2" style={{ float: "left" }}>
                                    {/* <FaYoutube style={{width: "1.5em", height: "1.5em", fill: "white"}}/> */}
                                    <img src="/images/footer/youtube.png" alt="youtube_logo" className='w-8 h-8 cursor-pointer transform hover:scale-110' />
                                </a>
                                <a href="https://www.facebook.com/Corcym" target="_blank" rel="noreferrer"
                                    className="social-link px-2" style={{ float: "left" }}>
                                    {/* <FaYoutube style={{width: "1.5em", height: "1.5em", fill: "white"}}/> */}
                                    <img src="/images/footer/facebook.png" alt="facebook_logo" className='w-8 h-8 cursor-pointer transform hover:scale-110' />
                                </a>
                                <a href="https://www.instagram.com/corcym_official?igsh=bHB6bzBvMzd0cjFn" target="_blank" rel="noreferrer"
                                    className="social-link px-2" style={{ float: "left" }}>
                                    {/* <FaYoutube style={{width: "1.5em", height: "1.5em", fill: "white"}}/> */}
                                    <img src="/images/footer/instagram.png" alt="instagram_logo" className='w-8 h-8 cursor-pointer transform hover:scale-110' />
                                </a>
                            </div>
                            <br />
                            <Link to="/media-library" className="block text-gray-lighter text-sm xl:text-base mb-1 lg:mb-3 mt-6">
                                Media Library
                            </Link>
                            <Link to="/news" className="block text-gray-lighter text-sm xl:text-base mb-1 lg:mb-3">
                                News
                            </Link>

                        </div>
                        {/* grid item end */}

                        {/* grid item start */}
                        {/* <div className="border-l-4 border-red-primary px-4 lg:col-start-4">
                                <div className="mb-6">
                                    <p className="font-bold text-gray-lighter text-xs md:text-sm lg:text-base  mb-1 lg:mb-2">
                                    Corcym Srl
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    Via Crescentino sn
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    13040 Saluggia (VC) Italy
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                    Теl: +39 0161 487800
                                    </p>
                                </div>
                                <div className="mb-6">
                                    <p className="font-bold text-gray-lighter text-xs md:text-sm lg:text-base  mb-1 lg:mb-2">
                                        Corcym Canada Corp.
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        5005 North Fraser Way
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        Burnaby, BC V5J 5M1 Canada
                                    </p>
                                    <p className="text-gray-lighter text-xs md:text-sm lg:text-base  mb-1">
                                        <a href="tel:+6044125650">Tel: +604 412-5650</a>
                                    </p>
                                </div>

                            </div> */}
                        {/* grid item end */}
                    </div>
                    {/* news grid end */}

                    <p className="text-xs text-white text-left mt-5">
                        © 2024 Corcym Group. All rights reserved.
                    </p>
                    <p className="text-xs text-white text-right mt-5 italic ">
                        Designed and developed by <a className="underline" href="https://www.kaffeina.it">Kaffeina.it</a>
                    </p>


                </div>
                {/* container content end */}
                {/* section flex end */}


            </div>
        </>
    )
}

export default Footer
