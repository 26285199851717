import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { FiSearch, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";
import { links } from "../../routes/NormalRoutes/Links";
import Submenu from "./Submenu";

import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Axios } from '../../api';
import { logout } from '../../redux/slices/userSlice';
import { error_alert, removeUserOnboardingData, success_alert } from '../../utils';
import { get_initial_courses } from '../../redux/slices/coursesSlice';
import { CAREER_EXTERNAL_LINK } from '../../constants';


const Sidebar = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname;

    const user = useSelector((state) => state.user);

    const handleLogout = async () => {
        try {
            const result = await Axios.get("users/logout");
            dispatch(logout());
            removeUserOnboardingData();
            dispatch(get_initial_courses());
            if (path.includes("/academy/courses/course")) {
                // history.push(`/academy/courses`);
                history.push(`/academy/mastering-trans-axillary`);
            } else {
                history.push(`${path}`);
            }
            success_alert(
                result?.data?.description
                    ? result.data.description
                    : "Logout successfully!"
            );
        } catch (e) {
            if (e.response) {
                console.log(e.response.data);
                error_alert(JSON.stringify(e.response.data.description));
            } else {
                error_alert("Network Error");
            }
        }
    };

    const [showSubmenu, setShowSubmenu] = useState(null);
    const [dropdown, setDropdown] = useState(false);

    const showSearch = () => {
        props.setSearchOverlay(true);
    }


    //first submenu
    const showSubmenuHandler = (menuVoice) => {

        if (typeof menuVoice == "undefined" || menuVoice.length <= 0) {
            if (showSubmenu || showSubmenu == 0) {
                document.getElementById("hamburgerMenu" + showSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerMenu" + showSubmenu).classList.add("text-white");
            }

            closeSubmenuHandler();

            return false;
        }

        if (typeof links[menuVoice] == "undefined" || typeof links[menuVoice].submenu == "undefined") {
            if (showSubmenu || showSubmenu == 0) {
                document.getElementById("hamburgerMenu" + showSubmenu).classList.remove("text-red-primary");
                document.getElementById("hamburgerMenu" + showSubmenu).classList.add("text-white");
            }

            closeSubmenuHandler();

            return false;
        }

        if (dropdown && showSubmenu == menuVoice) {
            document.getElementById("hamburgerMenu" + showSubmenu).classList.remove("text-red-primary")
            document.getElementById("hamburgerMenu" + showSubmenu).classList.add("text-white");

            setDropdown(false);
        }
        else {
            if (showSubmenu || showSubmenu == 0) {
                document.getElementById("hamburgerMenu" + showSubmenu).classList.remove("text-red-primary")
                document.getElementById("hamburgerMenu" + showSubmenu).classList.add("text-white");
            }

            document.getElementById("hamburgerMenu" + menuVoice).classList.add("text-red-primary");
            setDropdown(true);
        }

        setShowSubmenu(menuVoice);

        return true;
    }

    const closeSubmenuHandler = () => {
        setDropdown(false);
        setShowSubmenu(null);

        return true;
    }


    return (
        <div className={`${props.sidebar ? 'translate-x-0' : 'translate-x-full'} top-0 bottom-0 right-0 transform transition bg-blue-primary fixed pt-32 px-4 sidebar lg:hidden`}>
            {/* searchbar */}
            <div className="inline-block mb-6 cursor-pointer">
                <span className="text-3xl text-white">
                    <FiSearch onClick={showSearch} />
                </span>
            </div>
            {/* searchbar */}
            {/* links */}
            <div className="mb-6 border-b-2 border-white w-full flex flex-col flex-wrap lg:flex-no-wrap">
                {links.map((link, key) => (
                    link.submenu ?
                        <div className="w-full mb-4 text-white" key={key} id={`hamburgerMenu` + key}>
                            <div className='w-4/5 flex items-center justify-start' style={{ float: 'left' }}>
                                <a className='uppercase text-base font-medium block focus:outline-none text-left' href={link.to} aria-haspopup="menu">{link.title}</a>
                            </div>
                            <div className='w-1/5 flex items-center justify-end left' style={{ float: 'left' }}>
                                {
                                    showSubmenu == key && dropdown ?
                                        <FiChevronUp className="mx-2 cursor-pointer" onClick={() => showSubmenuHandler(key)} style={{ float: 'right' }} />
                                        :
                                        <FiChevronDown className="mx-2 cursor-pointer" onClick={() => showSubmenuHandler(key)} style={{ float: 'right' }} />
                                }
                            </div>
                            {
                                showSubmenu == key && dropdown ?
                                    <div className='w-full'>
                                        <Submenu
                                            submenus={links[showSubmenu].submenu}
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                        :
                        <Link onClick={() => props.setSidebar(!props.sidebar)} key={key} className="uppercase text-base text-white font-medium block mb-6 focus:outline-none" to={link.to}>
                            {link.title}
                        </Link>
                ))}
            </div>
            {/* links */}

            {/* social */}
            <div className="mb-2">
                <span className="uppercase text-base text-white font-medium block mb-2 focus:outline-none">FOLLOW US ON:</span>

                <div style={{ height: "1.3em" }}>
                    <a href="https://twitter.com/CORCYM_OFFICIAL" target="_blank"
                        className="text-xl text-white mb-4 block pr-2" style={{ float: "left" }}>
                        {/* <FaTwitter style={{width: "1.3em", height: "1.3em", fill: "white"}}/> */}
                        <img src="/images/footer/twitter.png" alt="twitter_logo" className='w-7 h-7 cursor-pointer transform hover:scale-110' />
                    </a>
                    <a href="https://www.linkedin.com/company/corcym" target="_blank"
                        className="text-xl text-white mb-4 block px-2" style={{ float: "left" }}>
                        {/* <FaLinkedin style={{ width: "1.3em", height: "1.3em", fill: "white" }} /> */}
                        <img src="/images/footer/linkdIn.png" alt="linkdIn_logo" className='w-7 h-7 cursor-pointer transform hover:scale-110' />
                    </a>
                    <a href="https://www.youtube.com/channel/UCem2dzdF2kzV-gL0plAHVEA" target="_blank"
                        className="text-xl text-white mb-4 block px-2" style={{ float: "left" }}>
                        {/* <FaYoutube style={{ width: "1.3em", height: "1.3em", fill: "white" }} /> */}
                        <img src="/images/footer/youtube.png" alt="youtube_logo" className='w-7 h-7 cursor-pointer transform hover:scale-110' />
                    </a>
                    <a href="https://www.facebook.com/Corcym" target="_blank"
                        className="text-xl text-white mb-4 block px-2" style={{ float: "left" }}>
                        {/* <FaYoutube style={{ width: "1.3em", height: "1.3em", fill: "white" }} /> */}
                        <img src="/images/footer/facebook.png" alt="facebook_logo" className='w-7 h-7 cursor-pointer transform hover:scale-110' />
                    </a>
                    <a href="https://www.instagram.com/corcym_official?igsh=bHB6bzBvMzd0cjFn" target="_blank"
                        className="text-xl text-white mb-4 block px-2" style={{ float: "left" }}>
                        {/* <FaYoutube style={{ width: "1.3em", height: "1.3em", fill: "white" }} /> */}
                        <img src="/images/footer/instagram.png" alt="instagram_logo" className='w-7 h-7 cursor-pointer transform hover:scale-110' />
                    </a>
                </div>

                {/* <Link to="/careers" className="uppercase text-base text-white font-medium block mb-6 focus:outline-none">Careers</Link> */}
                <a href="/media-library" className="uppercase text-base text-white font-medium block mt-10 mb-2 focus:outline-none">Media Library</a>
                <a href="/news" className="uppercase text-base text-white font-medium block mb-2 focus:outline-none">News</a>
                <a href={CAREER_EXTERNAL_LINK} className="uppercase text-base text-white font-medium block mb-4 focus:outline-none">Careers</a>
            </div>

            {user && path.includes("/academy") &&
                <div className="mb-6 border-t-2 border-white w-full flex flex-wrap lg:flex-no-wrap py-2">
                    <div
                        onClick={handleLogout}
                        className="uppercase text-base text-white font-medium block mb-2 focus:outline-none cursor-pointer">
                        Logout
                    </div>
                </div>
            }
            {/* social */}
        </div>
    )
}

export default Sidebar
