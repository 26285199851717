import React, { Suspense, useState, createContext, useEffect } from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import { CAREER_EXTERNAL_LINK, MINIMALLY_INVASIVE_INSTRUMENTS } from '../../constants';
//components
import Loader from "../../components/Loader";
import Sidebar from "../../components/Sidebar";
import Overlay from "../../components/Overlay";
import Solosmart from '../../pages/DeviceDetail/SoloSmart';
import Memo3DR from '../../pages/MitralDeviceDetail/Memo3D_R';
import Cookie from '../../components/Cookie';
import ChangeChoiceCookie from '../../components/ChangeChoiceCookie';
import SearchPopUp from '../../components/SearchLayout';

import ResourceRequestForm from '../../components/ResourceRequestForm/ResourceRequestForm';
import { RequestFormContext } from '../../utils';

const Navbar = React.lazy(() => import("../../components/Navbar"));

//components

//pages import below
const Home = React.lazy(() => import("../../pages/Home"));
const DeviceHomePage = React.lazy(() => import("../../pages/Devices"));
const DeviceListingPage = React.lazy(() => import("../../pages/DevicesListing"));
const About = React.lazy(() => import("../../pages/About"));
const Contact = React.lazy(() => import("../../pages/Contact"));
const News = React.lazy(() => import("../../pages/News"));
const NewsDetailPage = React.lazy(() => import("../../pages/NewsDetailPage/NewsDetailPage"));
const PressDetailPage = React.lazy(() => import("../../pages/PressDetailPage/PressDetailPage"));

const Careers = React.lazy(() => import("../../pages/Careers"));
const DetailJob = React.lazy(() => import("../../pages/JobDetailPage"));
const ProfessionalEducation = React.lazy(() => import("../../pages/ProfessionalEducation"));
const Eras = React.lazy(() => import("../../pages/ProfessionalEducation/eras"));
const CtScan = React.lazy(() => import("../../pages/ProfessionalEducation/CtScan"));
const PatientsAndCaregivers = React.lazy(() => import("../../pages/PatientsAndCaregivers"));

//Academy
const HomeAcademy = React.lazy(() => import("../../pages/Academy"));
const GetStarted = React.lazy(() => import("../../pages/Academy/GetStarted"));
const AVR = React.lazy(() => import("../../pages/Academy/AVR"));
const Annuloplasty = React.lazy(() => import("../../pages/Academy/Annuloplasty"));
const AnnuloplastyHaart = React.lazy(() => import("../../pages/Academy/AnnuloplastyHaart"));
const MasteringMiniSternotomy = React.lazy(() => import("../../pages/Academy/MasteringMiniSternotomy"));
const MasteringRat = React.lazy(() => import("../../pages/Academy/MasteringRat"));
const MasteringTransAxillary = React.lazy(() => import("../../pages/Academy/MasteringTransAxillary"));
const MasteringEndoscopy = React.lazy(() => import("../../pages/Academy/MasteringEndoscopy"));
const MasteringRobotics = React.lazy(() => import("../../pages/Academy/MasteringRobotics"));
const OnlineCourses = React.lazy(() => import("../../pages/Academy/OnlineCourses"));

//Media Library
const HomeMediaLibrary = React.lazy(() => import("../../pages/MediaLibrary"));
const AorticMediaLibrary = React.lazy(() => import("../../pages/MediaLibrary/Aortic"));
const MitralMediaLibrary = React.lazy(() => import("../../pages/MediaLibrary/Mitral"));
const VideoMediaLibrary = React.lazy(() => import("../../pages/MediaLibrary/video"));

//Terms & conditions, Privacy policy page imports

const Terms = React.lazy(() => import("../../pages/Terms"));
const Cookies = React.lazy(() => import("../../pages/Cookies"));
const Legal = React.lazy(() => import("../../pages/Legal"));
const Privacy = React.lazy(() => import("../../pages/Privacy"));
const PrivacyStatement = React.lazy(() => import("../../pages/Privacy/privacy-statement"));
const Privacy_Information_Notice = React.lazy(() => import("../../pages/Privacy_Information_Notice for survey"));
const Disclaimer = React.lazy(() => import("../../pages/Disclaimer"));

//Terms & conditions, Privacy policy page imports of Corcym Academy

const PrivacyAcademy = React.lazy(() => import("../../pages/Privacy-academy"));
const TermsAcademy = React.lazy(() => import("../../pages/Terms-academy"));
const LegalAcademy = React.lazy(() => import("../../pages/Legal-academy"));
const CookiesAcademy = React.lazy(() => import("../../pages/Cookies-academy"));
const PrivacyPolicyCSPRO = React.lazy(() => import("../../pages/Privacy-policy-CSPRO"));
const PrivacyPolicyBroucher = React.lazy(() => import("../../pages/Broucher-privacy-policy"));


//Coronavirus Updates page
const Coronavirus = React.lazy(() => import("../../pages/Coronavirus"));

//Day One Press Release page
const DayOne = React.lazy(() => import("../../pages/Press-release"));
const DayTwo = React.lazy(() => import("../../pages/Press-release/JulySixteenth"));
const DayThree = React.lazy(() => import("../../pages/Press-release/DecemberThird"));
const DayFour = React.lazy(() => import("../../pages/Press-release/JanuaryEleven"));


//Sub pages of about-us page import below
const EthicsAndIntegrity = React.lazy(() => import("../../pages/About/Ethics&Integrity"));
const TeamOld = React.lazy(() => import("../../pages/About/team-old"));

const Team = React.lazy(() => import("../../pages/About/Team"));
const ExecutiveLeader = React.lazy(() => import("../../pages/About/ExecutiveLeader"));
const MeetTeam = React.lazy(() => import("../../pages/About/MeetTeam"));
const ScientificAdvisoryBoard = React.lazy(() => import("../../pages/About/ScientificAdvisoryBoard"));

//Sub pages of contact-us page import below
const CustomerService = React.lazy(() => import("../../pages/Contact/CustomerService"));
const Investigator = React.lazy(() => import("../../pages/Contact/Investigator"));
const Donations = React.lazy(() => import("../../pages/Contact/Donations"));
const Safety = React.lazy(() => import("../../pages/Contact/Safety"));
const PartnerWithUs = React.lazy(() => import("../../pages/Partner-with-us/PartnerWithUs"));
const RNBDPartnership = React.lazy(() => import("../../pages/Partner-with-us/RnbdPartnership"));
const CDMOPartnership = React.lazy(() => import("../../pages/Partner-with-us/CdmoPartnership"));
const OperativeInstruction = React.lazy(() => import("../../pages/Contact/OperativeInstruction"));
const OperativeInstructionFrench = React.lazy(() => import("../../pages/Contact/OperativeInstructionFrench"));
//pages import above

//aortic devices pages import below
const Perceval = React.lazy(() => import("../../pages/DeviceDetail/Perceval"));
const PercevalPlus = React.lazy(() => import("../../pages/DeviceDetail/PercevalPlus"));
const PercevalPlusLancelot = React.lazy(() => import("../../pages/DeviceDetail/PercevalPlusLancelot"));

const SoloSmart = React.lazy(() => import("../../pages/DeviceDetail/SoloSmart"));
const CrownPrt = React.lazy(() => import("../../pages/DeviceDetail/CrownPrt"));
const BiCarbon = React.lazy(() => import("../../pages/DeviceDetail/Bicarbon"));
const CarboMedics = React.lazy(() => import("../../pages/DeviceDetail/Carbomedics"));
const Conduits = React.lazy(() => import("../../pages/DeviceDetail/Conduits"));

//US page imports
const PercevalUS = React.lazy(() => import("../../pages/DeviceDetail/US/Perceval"));
const PercevalPlusUS = React.lazy(() => import("../../pages/DeviceDetail/US/PercevalPlus"));
const SoloSmartUS = React.lazy(() => import("../../pages/DeviceDetail/US/SoloSmart"));
const CarboMedicsUS = React.lazy(() => import("../../pages/DeviceDetail/US/Carbomedics"));
const ConduitsUS = React.lazy(() => import("../../pages/DeviceDetail/US/Conduits"));
const Haart200US = React.lazy(() => import("../../pages/DeviceDetail/US/Haart200"));
const Haart300US = React.lazy(() => import("../../pages/DeviceDetail/US/Haart300"));
//aortic devices pages import above

//Mitral devices pages import below
const Memo4D = React.lazy(() => import("../../pages/MitralDeviceDetail/Memo4D"));
const Memo3DRechord = React.lazy(() => import("../../pages/MitralDeviceDetail/Memo3D_R"));
const Memo3D = React.lazy(() => import("../../pages/MitralDeviceDetail/Memo3D"));
const AnnuloFlex = React.lazy(() => import("../../pages/MitralDeviceDetail/AnnuloFlex"));
const AnnuloFlo = React.lazy(() => import("../../pages/MitralDeviceDetail/AnnuloFlo"));
const Sovering = React.lazy(() => import("../../pages/MitralDeviceDetail/Sovering"));
const CarboMedicsM = React.lazy(() => import("../../pages/MitralDeviceDetail/CarboMedics"));
const BiCarbonM = React.lazy(() => import("../../pages/MitralDeviceDetail/BiCarbon"));
const PeriCarbonM = React.lazy(() => import("../../pages/MitralDeviceDetail/PeriCarbon_M"));
const Haart200 = React.lazy(() => import("../../pages/DeviceDetail/Haart200"));
const Haart300 = React.lazy(() => import("../../pages/DeviceDetail/Haart300"));

//US page imports
const Memo3DUS = React.lazy(() => import("../../pages/MitralDeviceDetail/US/Memo3D"));
const Memo4DUS = React.lazy(() => import("../../pages/MitralDeviceDetail/US/Memo4D"));
const AnnuloFlexUS = React.lazy(() => import("../../pages/MitralDeviceDetail/US/AnnuloFlex"));
const AnnuloFloUS = React.lazy(() => import("../../pages/MitralDeviceDetail/US/AnnuloFlo"));
const CarbomedicsUS = React.lazy(() => import("../../pages/MitralDeviceDetail/US/CarboMedics"));

//Mitral devices pages import above


//Miami devices pages import below
// US

const AtrialLifeSystem = React.lazy(() => import("../../pages/MiamiDeviceDetail/AtrialLifeSystem"));
const Detach = React.lazy(() => import("../../pages/MiamiDeviceDetail/Detach"));
const IntercostalRetractor = React.lazy(() => import("../../pages/MiamiDeviceDetail/IntercostalRetractorSys"));
const KnotPusher = React.lazy(() => import("../../pages/MiamiDeviceDetail/KnotPusher"));
const Tie = React.lazy(() => import("../../pages/MiamiDeviceDetail/Tie"));
const SutureBelt = React.lazy(() => import("../../pages/MiamiDeviceDetail/SutureBelt"));
const Cuff = React.lazy(() => import("../../pages/MiamiDeviceDetail/Cuff"));
const Collar = React.lazy(() => import("../../pages/MiamiDeviceDetail/Collar"));

//Miami devices pages import above

//Hidden pages
const VideoCCMK001020A = React.lazy(() => import("../../pages/HiddenPages/video-CC-MK-001020A"));
const VideoCCMK00371A = React.lazy(() => import("../../pages/HiddenPages/video-CC-MK-00371A"));
const FileHVVLS7300002 = React.lazy(() => import("../../pages/HiddenPages/file-HVV-LS-730-0002"));
const FileCCMK00263C = React.lazy(() => import("../../pages/HiddenPages/file-CC-MK-00263C"));
const FileE0191202205A = React.lazy(() => import("../../pages/HiddenPages/file-E-0191-2022-05-A"));
const FileHVVLS7300004RevE = React.lazy(() => import("../../pages/HiddenPages/file-HVV_LS-730-0004-rev-E"));
const DeviceTrackingWebApp = React.lazy(() => import("../../pages/HiddenPages/device-tracking-web-app"));
const DeviceTrackingWebAppTest = React.lazy(() => import("../../pages/HiddenPages/device-tracking-web-app-test"));

const Login = React.lazy(() => import('../../pages/Login'));
const Signup = React.lazy(() => import('../../pages/Signup'));
const ForgotPassword = React.lazy(() => import("../../pages/ActivateAccount/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../../pages/ActivateAccount/ResetPassword"));
const RecoverPassword = React.lazy(() => import("../../pages/ActivateAccount/RecoverPassword"));

const NormalRoutes = () => {

    const [requestFormState, setRequestFormState] = useState(false);
    const [broucherValue, setBroucherValue] = useState('');
    const [btnRef, setBtnRef] = useState('');
    const contextValue = { requestFormState, setRequestFormState, broucherValue, setBroucherValue, btnRef, setBtnRef };

    const [sidebar, setSidebar] = useState(false);
    const [cookie, setCookie] = useState(
        document.cookie.match('(^|;)\\s*cookies\\s*=\\s*([^;]+)')?.pop() || '' == "accept" ? false : true
    );

    const [searchOverlay, setSearchOverlay] = useState(false);
    // const [jobData, setJobData] = useState({
    //     // title:"react developer",
    //     // desc: "descriptiondescription descriptiondescription description description",
    // });

    let timeoutEnergySaving;

    //listener event mouse for add-remove energy saving mode
    const listenerMouseMove = () => {
        document.getElementById("energySaving").style.display = "none";
        document.getElementById("root").style.display = "";

        clearTimeout(timeoutEnergySaving);

        timeoutEnergySaving = setTimeout(activeEnergySaving, 120000); //2 minutes

        return true;
    };


    document.addEventListener('mousemove', listenerMouseMove, false);
    document.addEventListener('scroll', listenerMouseMove, false);


    //method for add class energySaving
    const activeEnergySaving = () => {
        document.getElementById("energySaving").style.display = "";
        document.getElementById("root").style.display = "none";
    };

    return (
        <div className="relative overflow-hidden">
            <Suspense fallback={<Loader />}>

                {searchOverlay ? <SearchPopUp searchOverlay={searchOverlay} setSearchOverlay={setSearchOverlay} /> : null}

                <Navbar sidebar={sidebar} setSidebar={setSidebar} searchOverlay={searchOverlay} setSearchOverlay={setSearchOverlay} />
                <Sidebar sidebar={sidebar} setSidebar={setSidebar} searchOverlay={searchOverlay} setSearchOverlay={setSearchOverlay} />

                {cookie ? <Cookie setCookie={setCookie} /> : null}
                {!cookie ? <ChangeChoiceCookie setCookie={setCookie} /> : null}

                {sidebar ? <Overlay searchOverlay={setSidebar} class={'lg:hidden'} /> : null}



                <RequestFormContext.Provider value={contextValue}>

                    {
                        requestFormState ?
                            <>
                                <div className="w-screen h-screen fixed flex justify-center bg-black bg-opacity-60" style={{ zIndex: 10001 }}>
                                    <ResourceRequestForm />
                                </div>
                            </>
                            :
                            null
                    }

                    <Switch>
                        <Route path="/" exact>
                            <Home />
                        </Route>
                        <Route path="/devices" exact>
                            <DeviceHomePage />
                        </Route>
                        <Route path="/devices/aortic" exact>
                            <DeviceListingPage title="aortic" primaryColor="red-primary" secondaryColor="blue-primary" />
                        </Route>
                        <Route path="/devices/mitral" exact>
                            <DeviceListingPage title="mitral" primaryColor="blue-primary" secondaryColor="red-primary" />
                        </Route>
                        <Route path="/devices/miami-instruments" exact>
                            <DeviceListingPage title={MINIMALLY_INVASIVE_INSTRUMENTS} primaryColor="blue-primary" secondaryColor="red-primary" />
                        </Route>

                        {/* aortic device individual pages below */}
                        <Route path="/devices/aortic/perceval" exact>
                            <Perceval />
                        </Route>
                        <Route path="/devices/aortic/perceval-plus" exact>
                            <PercevalPlus />
                        </Route>
                        <Route path="/devices/aortic/perceval-plus-lancelot" exact>
                            <PercevalPlusLancelot />
                        </Route>
                        {/* <Route path="/devices/aortic/perceval-plus#video" exact>
                            <PercevalPlus />
                        </Route>
                        <Route path="/devices/aortic/perceval-plus#references" exact>
                            <PercevalPlus />
                        </Route> */}
                        <Route path="/devices/aortic/solo-smart" exact>
                            <SoloSmart />
                        </Route>
                        <Route path="/devices/aortic/crown-prt" exact>
                            <CrownPrt />
                        </Route>
                        <Route path="/devices/aortic/bi-carbon" exact>
                            <BiCarbon />
                        </Route>
                        <Route path="/devices/aortic/carbo-medics" exact>
                            <CarboMedics />
                        </Route>
                        <Route path="/devices/aortic/conduits" exact>
                            <Conduits />
                        </Route>
                        {/* aortic device individual pages above */}

                        {/* US aortic device individual pages above */}
                        <Route path="/devices/aortic/US/perceval" exact>
                            <PercevalUS />
                        </Route>
                        <Route path="/devices/aortic/US/perceval-plus" exact>
                            <PercevalPlusUS />
                        </Route>
                        <Route path="/devices/aortic/US/solo-smart" exact>
                            <SoloSmartUS />
                        </Route>
                        <Route path="/devices/aortic/US/carbo-medics" exact>
                            <CarboMedicsUS />
                        </Route>
                        <Route path="/devices/aortic/US/conduits" exact>
                            <ConduitsUS />
                        </Route>
                        <Route path="/devices/aortic/US/haart-200" exact>
                            <Haart200US />
                        </Route>
                        <Route path="/devices/aortic/US/haart-300" exact>
                            <Haart300US />
                        </Route>

                        <Route path="/devices/aortic/haart-200" exact>
                            <Haart200 />
                        </Route>
                        <Route path="/devices/aortic/haart-300" exact>
                            <Haart300 />
                        </Route>

                        {/* US aortic device individual pages above */}

                        {/* Mitral device individual pages below */}

                        <Route path="/devices/mitral/memo4d" exact>
                            <Memo4D />
                        </Route>
                        <Route path="/devices/mitral/memo3d-rechord" exact>
                            <Memo3DRechord />
                        </Route>
                        <Route path="/devices/mitral/memo3d" exact>
                            <Memo3D />
                        </Route>
                        <Route path="/devices/mitral/annuloflex" exact>
                            <AnnuloFlex />
                        </Route>
                        {/* <Route path="/devices/mitral/annuloflo" exact>
                            <AnnuloFlo />
                        </Route> */}
                        <Route path="/devices/mitral/carbomedics-m" exact>
                            <CarboMedicsM />
                        </Route>
                        <Route path="/devices/mitral/sovering" exact>
                            <Sovering />
                        </Route>
                        <Route path="/devices/mitral/bicarbon-m" exact>
                            <BiCarbonM />
                        </Route>
                        <Route path="/devices/mitral/pericarbon-m" exact>
                            <PeriCarbonM />
                        </Route>

                        {/* Mitral device individual pages above */}

                        {/* US Mitral device individual pages above */}
                        <Route path="/devices/mitral/US/memo3d" exact>
                            <Memo3DUS />
                        </Route>
                        <Route path="/devices/mitral/US/memo4d" exact>
                            <Memo4DUS />
                        </Route>
                        <Route path="/devices/mitral/US/annuloflex" exact>
                            <AnnuloFlexUS />
                        </Route>
                        <Route path="/devices/mitral/US/annuloflo" exact>
                            <AnnuloFloUS />
                        </Route>
                        <Route path="/devices/mitral/US/carbomedics" exact>
                            <CarbomedicsUS />
                        </Route>

                        {/* US Mitral device individual pages above */}

                        {/* Miami Instruments Detail page below */}
                        {/* US Devices below */}
                        <Route path="/devices/miami/US/atrial-life-system" exact>
                            <AtrialLifeSystem />
                        </Route>
                        <Route path="/devices/miami/US/detach" exact>
                            <Detach />
                        </Route>
                        <Route path="/devices/miami/US/intercostal-retractor-system" exact>
                            <IntercostalRetractor />
                        </Route>
                        <Route path="/devices/miami/US/knot-pusher" exact>
                            <KnotPusher />
                        </Route>
                        <Route path="/devices/miami/US/tie" exact>
                            <Tie />
                        </Route>
                        <Route path="/devices/miami/US/suture-belt" exact>
                            <SutureBelt />
                        </Route>
                        <Route path="/devices/miami/US/cuff" exact>
                            <Cuff />
                        </Route>
                        <Route path="/devices/miami/US/collar" exact>
                            <Collar />
                        </Route>
                        {/* US Devices above */}
                        {/* Miami Instruments Detail page above */}

                        {/* only make changes below */}
                        <Route path="/about" exact>
                            <About />
                        </Route>
                        <Route path="/ethics" exact>
                            <EthicsAndIntegrity />
                        </Route>
                        <Route path="/academy" exact>
                            <HomeAcademy />
                        </Route>
                        <Route path="/academy/get-started" exact>
                            <GetStarted />
                        </Route>
                        <Route path="/academy/get-started/avr-perceval" exact>
                            <AVR />
                        </Route>
                        <Route path="/academy/get-started/annuloplasty-memo-4d" exact>
                            <Annuloplasty />
                        </Route>
                        <Route path="/academy/get-started/annuloplasty-haart" exact>
                            <AnnuloplastyHaart />
                        </Route>
                        <Route path="/academy/mastering-mini-sternotomy" exact>
                            <MasteringMiniSternotomy />
                        </Route>
                        <Route path="/academy/mastering-rat" exact>
                            <MasteringRat />
                        </Route>
                        <Route path="/academy/mastering-trans-axillary" exact>
                            <MasteringTransAxillary />
                        </Route>
                        <Route path="/academy/mastering-endoscopy" exact>
                            <MasteringEndoscopy />
                        </Route>
                        <Route path="/academy/mastering-robotics" exact>
                            <MasteringRobotics />
                        </Route>
                        
                        <Route path="/academy/courses" exact>
                            <OnlineCourses />
                        </Route>


                        <Route path="/media-library" exact>
                            <HomeMediaLibrary />
                        </Route>
                        <Route path="/media-library/aortic-solutions" exact>
                            <AorticMediaLibrary />
                        </Route>
                        <Route path="/media-library/:category/video/:subcategory/:id" exact>
                            <VideoMediaLibrary />
                        </Route>
                        <Route path="/media-library/mitral-solutions" exact>
                            <MitralMediaLibrary />
                        </Route>
                        <Route path="/media-library/:category/video/:subcategory/:id" exact>
                            <VideoMediaLibrary />
                        </Route>

                        {/* <Route path="/team" exact>
                            <TeamOld />
                        </Route> */}

                        <Route path="/team/meet-board" exact>
                            <Team />
                        </Route>
                        <Route path="/team/executive-leader" exact>
                            <ExecutiveLeader />
                        </Route>
                        <Route path="/team/scientific-advisory-board" exact>
                            <ScientificAdvisoryBoard />
                        </Route>
                        <Route path="/meet-the-team" exact>
                            <MeetTeam />
                        </Route>

                        <Route path="/contact-us" exact>
                            <Contact />
                        </Route>
                        <Route path="/customer-service" exact>
                            <CustomerService />
                        </Route>
                        <Route path="/donations-and-grants" exact>
                            <Donations />
                        </Route>
                        <Route path="/research" exact>
                            <Investigator />
                        </Route>
                        <Route path="/product-safety" exact>
                            <Safety />
                        </Route>
                        <Route path="/partner-with-us" exact>
                            <PartnerWithUs />
                        </Route>
                        <Route path="/rnbd-partnership" exact>
                            <RNBDPartnership />
                        </Route>
                        <Route path="/cdmo-partnership" exact>
                            <CDMOPartnership />
                        </Route>
                        <Route path="/operative-instruction" exact>
                            <OperativeInstruction />
                        </Route>
                        <Route path="/operative-instruction-french" exact>
                            <OperativeInstructionFrench />
                        </Route>


                        <Route path="/education" exact>
                            <ProfessionalEducation />
                        </Route>
                        <Route path="/eras" exact>
                            <Eras />
                        </Route>
                        <Route path="/ct-scan" exact>
                            <CtScan />
                        </Route>
                        <Route path="/patients-and-caregivers-other-countries" exact>
                            <PatientsAndCaregivers />
                        </Route>
                        <Route path="/patients-and-caregivers/:language" exact>
                            <PatientsAndCaregivers />
                        </Route>
                        <Route path="/news" exact>
                            <News />
                        </Route>
                        <Route path="/news-detail/:id" exact>
                            <NewsDetailPage />
                        </Route>
                        <Route path="/press-detail/:id" exact>
                            <PressDetailPage />
                        </Route>
                        <Route path="/terms" exact>
                            <Terms />
                        </Route>
                        <Route path="/cookies" exact>
                            <Cookies />
                        </Route>
                        <Route path="/legal" exact>
                            <Legal />
                        </Route>
                        <Route path="/privacy" exact>
                            <Privacy />
                        </Route>
                        <Route path="/privacy-statement" exact>
                            <PrivacyStatement />
                        </Route>
                        <Route path="/privacy-Information-notice-for-survey" exact>
                            <Privacy_Information_Notice />
                        </Route>
                        <Route path="/coronavirus" exact>
                            <Coronavirus />
                        </Route>
                        {/* <Route path="/careers" exact>
                            <Careers />
                        </Route> */}
                        {/* Redirecting career route to an external link */}
                        <Route path="/careers" component={() => {
                            window.location.href = CAREER_EXTERNAL_LINK;
                            return null;
                        }} />
                        <Route path="/disclaimer" exact>
                            <Disclaimer />
                        </Route>
                        {/* <Route path="/detail-job/:id" exact>
                            <DetailJob />
                        </Route> */}
                        <Route path="/detail-job" component={() => {
                            window.location.href = CAREER_EXTERNAL_LINK;
                            return null;
                        }} />
                        <Route path="/detail-job/:id" component={() => {
                            window.location.href = CAREER_EXTERNAL_LINK;
                            return null;
                        }} />

                        <Route path="/privacyacademy" exact>
                            <PrivacyAcademy />
                        </Route>
                        <Route path="/terms-academy" exact>
                            <TermsAcademy />
                        </Route>
                        <Route path="/cookie-academy" exact>
                            <CookiesAcademy />
                        </Route>
                        <Route path="/legal-academy" exact>
                            <LegalAcademy />
                        </Route>
                        <Route path="/privacy-cspro" exact>
                            <PrivacyPolicyCSPRO />
                        </Route>
                        <Route path="/privacy-policy-download-brochure" exact>
                            <PrivacyPolicyBroucher />
                        </Route>
                        <Route path="/press-release-day-one" exact>
                            <DayOne />
                        </Route>

                        <Route path="/corcym-announces-the-first-patient-enrolled-in-mantra-study" exact>
                            <DayTwo />
                        </Route>
                        <Route path="/corcym-announces-growth-financing" exact>
                            <DayThree />
                        </Route>
                        <Route path="/corcym-announces-first-patient-implanted-in-china" exact>
                            <DayFour />
                        </Route>

                        {/* Hidden Pages */}
                        <Route path="/hidden-pages/video-cc-mk-001020-a" exact>
                            <VideoCCMK001020A />
                        </Route>
                        <Route path="/hidden-pages/video-cc-mk-00371-a" exact>
                            <VideoCCMK00371A />
                        </Route>
                        <Route path="/hidden-pages/file-hvv-ls-730-0002" exact>
                            <FileHVVLS7300002 />
                        </Route>
                        <Route path="/hidden-pages/file-cc-mk-00263-c" exact>
                            <FileCCMK00263C />
                        </Route>FileE0191202205A
                        <Route path="/hidden-pages/file-e-0191-2022-05-a" exact>
                            <FileE0191202205A />
                        </Route>
                        <Route path="/hidden-pages/device-tracking-web-app" exact>
                            <DeviceTrackingWebApp />
                        </Route>
                        <Route path="/hidden-pages/device-tracking-web-app-test" exact>
                            <DeviceTrackingWebAppTest />
                        </Route>
                        <Route path="/hidden-pages/file-hvv-ls-730-0004-rev-e" exact>
                            <FileHVVLS7300004RevE />
                        </Route>
                        <Route path="/ifu" exact component={() => {
                            window.location.href = 'https://biostable-s-e.com/ifu/ ';
                            return null;
                        }} />
                        <Route path="/media-library/biostable" exact component={() => {
                            window.location.href = 'https://biostable-s-e.com ';
                            return null;
                        }} />

                        <Route path="/login" exact>
                            <Login />
                        </Route>

                        {/* <Route path="/signup" exact>
                            <Signup />
                        </Route> */}
                        
                        {/* <Route path="/forgot-password" exact>
                            <ForgotPassword />
                        </Route>
                        <Route path="/reset-password/:token" exact>
                            <ResetPassword />
                        </Route>
                        <Route path="/recover-password/:token" exact>
                            <RecoverPassword />
                        </Route> */}

                        {/* only make changes above */}

                        <Redirect from="/academy" to="/login" />
                        <Redirect from="*" to="/" />
                    </Switch>
                </RequestFormContext.Provider>

            </Suspense>
        </div>
    )
}

export default NormalRoutes
